import { useStaticQuery, graphql } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useServiceQuery = () => {
  const { allSanityService } = useStaticQuery(graphql`
    query {
      allSanityService {
        services: nodes {
          title
        }
      }
    }
  `);
  return allSanityService || {};
};
