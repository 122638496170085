import { useStaticQuery, graphql } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useLocationQuery = () => {
  const { allSanityLocation } = useStaticQuery(graphql`
    query {
      allSanityLocation {
        locations: nodes {
          locationName
        }
      }
    }
  `);
  return allSanityLocation || {};
};
