import { useStaticQuery, graphql } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useIndustryQuery = () => {
  const { allSanityIndustry } = useStaticQuery(graphql`
    query {
      allSanityIndustry {
        industries: nodes {
          industryName
        }
      }
    }
  `);
  return allSanityIndustry || {};
};
